.dark-mode footer a {
    color: #9da6ac;
    text-decoration: underline;
}

.amplify-button[data-variation='primary'] {
    background-color: #e5007d;
}

.amplify-button[data-variation='primary']:hover {
    background-color: #c2006a;
}

table a {
    color: #e5007d;
}

table a:hover {
    color: #ff4ead !important;
}

.ssText {
    color: #e5007d;
}

.ssBackground {
    background-color: #e5007d;
    color: #ffffff;
}

.ssBackground:hover {
    background-color: #c2006a;
    color: #ffffff;
}